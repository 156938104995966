
import { Component, Vue } from 'vue-property-decorator';
import { mdiCheckCircle, mdiCloseCircleOutline, mdiPlusCircleOutline } from '@mdi/js';

import { UserModule } from '@/store/modules';
import { UserService } from '@/services';
import { IAuthUser, IUserUpdateFields } from '@/types/user.types';

@Component
export default class Notifications extends Vue {
  showPhone = false;

  notificationSettings = this.user?.notificationSettings || {
    sms: false,
    transactionSms: false,
    transactionEmail: false,
    marketingSms: false,
    marketingEmail: false
  };

  icons = {
    checkCircle: mdiCheckCircle,
    closeCircle: mdiCloseCircleOutline,
    plusCircle: mdiPlusCircleOutline
  };

  udata = {
    mobilePhoneNumber: this.user?.mobilePhoneNumber
  };

  get mobileFormated() {
    return Vue.filter('phone')(this.udata.mobilePhoneNumber);
  }

  set mobileFormated(input: string) {
    const clean = input.replaceAll(' ', '');
    this.udata.mobilePhoneNumber = clean.length < 11 ? clean : this.udata.mobilePhoneNumber;
  }

  get user(): IAuthUser | null {
    return UserModule.authenticatedUser;
  }

  get phoneNumberExists(): boolean {
    return Boolean(this.user?.mobilePhoneNumber);
  }

  async updateSettings(): Promise<void> {
    try {
      await UserService.updateProfile({
        notificationSettings: this.notificationSettings
      });
      await UserService.updateProfile(this.udata);
      this.showPhone = false;

      let user = await UserService.fetchUser();
      UserModule.setUser(user);
      this.$notifySuccess('Notifications have been saved');
    } catch (e: any) {
      this.$notifyError(this.$getError(e));
    }
  }

  async updatePhone(userData: IUserUpdateFields): Promise<void> {
    try {
      await UserService.updateProfile(userData);
      // update user state
      let user = await UserService.fetchUser();
      UserModule.setUser(user);
      this.showPhone = false;
      this.$notifySuccess('Notifications have been saved');
    } catch (e: any) {
      this.$notifyError(this.$getError(e));
    }
  }
}
